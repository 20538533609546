var omanCities = {
    "Muscat":"Masqaţ",
    "As Sīb":"Masqaţ",
    "Maţraḩ":"Masqaţ",
    "Şalālah":"Z̧ufār",
    "‘Ibrī":"Ad Dākhilīyah",
    "Nizwá":"Ad Dākhilīyah",
    "Khaşab":"Musandam",
    "Al Mazyūnah":"Z̧ufār",
    "Al Buraymī":"Al Buraymī",
    "Şuḩār":"Shamāl al Bāţinah",
    "Ar Rustāq":"Janūb al Bāţinah",
    "Şūr":"Janūb ash Sharqīyah",
    "Ibrā’":"Shamāl ash Sharqīyah",
    "Haymā’":"Al Wusţá"
}

export default omanCities;