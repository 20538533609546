var ksaCities = {
    "Riyadh":"Ar Riyāḑ",
    "Jeddah":"Makkah al Mukarramah",
    "Mecca":"Makkah al Mukarramah",
    "Medina":"Al Madīnah al Munawwarah",
    "Ad Dammām":"Ash Sharqīyah",
    "Al Hufūf":"Ash Sharqīyah",
    "Buraydah":"Al Qaşīm",
    "Al Ḩillah":"Ar Riyāḑ",
    "Aţ Ţā’if":"Makkah al Mukarramah",
    "Tabūk":"Tabūk",
    "Khamīs Mushayţ":"‘Asīr",
    "Ḩā’il":"Ḩā’il", 
    "Al Qaţīf":"Ash Sharqīyah",
    "Al Mubarraz":"Ash Sharqīyah",
    "Al Kharj":"Ar Riyāḑ",
    "Najrān":"Najrān",
    "Yanbu‘":"Al Madīnah al Munawwarah",
    "Abhā":"‘Asīr",
    "Arar":"Al Ḩudūd ash Shamālīyah",
    "Jāzān":"Jāzān",
    "Sakākā":"Al Jawf",
    "Al Bāḩah":"Al Bāḩah"
} 

export default ksaCities;