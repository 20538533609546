import * as HomeChartsTypes from './homeCharts.types';

const INITIAL_STATE = {
	areChartsLoading: true,
	netVolume: null,
	grossVolume: null,
	home_summary: {
		netVolume: null, 
		grossVolume: null, 
		balance: null,
	},
	paymentMethod: null,
	POSBranchs: null,
	totalRefunds: null,
	transaction: null,
	announcement: null,
	totalCustomer: null,
	totalTransfer: null,
	averagePerCustomer: null,
	date: null,
	comparisonDate: null,
	responseData: null,
	periodSelect:"t",
	channelType:"all",
	transactionsPerDay:null,
	transactionsPerBankCard:null
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case HomeChartsTypes.GET_NET_VOLUME:
		return {
			...state,
			netVolume: action.data,
		};
	case HomeChartsTypes.GET_GROSS_VOLUME:
		return {
			...state,
			grossVolume: action.data,
		};
	case HomeChartsTypes.GET_SUMMARY:
		return {
			...state,
			home_summary: {...action.data},
		};
	case HomeChartsTypes.GET_PAYMENT_METHOD:
		return {
			...state,
			paymentMethod: action.data,
		};
	case HomeChartsTypes.GET_POS_BRANCHES:
		return {
			...state,
			POSBranchs: action.data,
		};
	case HomeChartsTypes.GET_TOTAL_REFUNDS:
		return {
			...state,
			totalRefunds: action.data,
		};
	case HomeChartsTypes.GET_TRANSACTION:
		return {
			...state,
			transaction: action.data,
		};
	case HomeChartsTypes.GET_ANNOUNCEMENT:
		return {
			...state,
			announcement: action.data,
		};
	case HomeChartsTypes.GET_TOTAL_CUSTOMERS:
		return {
			...state,
			totalCustomer: action.data,
		};
	case HomeChartsTypes.GET_TOTAL_TRANSFER:
		return {
			...state,
			totalTransfer: action.data,
		};
	case HomeChartsTypes.GET_AVERAGE_PER_CUSTOMER:
		return {
			...state,
			averagePerCustomer: action.data,
		};
	case HomeChartsTypes.SET_DATE:
		return {
			...state,
			date: action.data,
		};
	case HomeChartsTypes.SET_COMPARISON_DATE:
		return {
			...state,
			comparisonDate: action.data,
		};
	case HomeChartsTypes.SET_TYPE_COMPARISON:
		return {
			...state,
			responseData: action.data
		};
	case HomeChartsTypes.SET_PERIOD_SELECT:
		return {
			...state,
			periodSelect: action.data
		};
	case HomeChartsTypes.SET_CHANNEL_TYPE:
		return {
			...state,
			channelType: action.data
		};
	case HomeChartsTypes.GET_TRANSACTIONS_PER_BANK_CARD:
		return {
			...state,
			transactionsPerBankCard: action.data
		};
	case HomeChartsTypes.GET_TRANSACTIONS_PER_DAY:
		return {
			...state,
			transactionsPerDay: action.data
		};
	case HomeChartsTypes.SET_CHARTS_LOADING:
		return {
			...state,
			areChartsLoading: action.payload.value
		};
	default:
		return state;
	}
}
