import React from 'react'
import { dependencies } from '../../../../tools/Dependencies'
import { Modal, ModalBody} from 'reactstrap'
// Images
import close_img from './../../../../assets/img/onboarding/close.svg'
// Redux
import { useSelector, useDispatch } from "react-redux"
import { updateOnboardingState } from '../../../../redux/Onboarding/onboarding.actions'

function CancelOnboarding(props) {
    // Redux State
    let state = useSelector(state => state.onboarding);
    const dispatch = useDispatch();
    const handle_submit_skip = () => {
        dependencies.addToLocalStorage("merchantIsLive", true);
        dependencies.addToLocalStorage("onboarding_btn", true);
        window.location.pathname = "/portal2/" + dependencies.getFromLocalStorage("lang") + "/home";
    }
    const renderModal = () => (
        <Modal isOpen={state.cancelModalOpen} className="onboarding_modal">
            <ModalBody>
                <h3>{props.Text.cancelOnboarding.header}<img src={close_img} alt="close" onClick={() =>dispatch(updateOnboardingState({...state, cancelModalOpen: false}))} /></h3>
                <p>{props.Text.cancelOnboarding.body}</p>
                <div className="buttons_container">
                    <button onClick={handle_submit_skip}>{props.Text.cancelOnboarding.skip}</button>
                    <button className="submit" onClick={() =>dispatch(updateOnboardingState({...state, cancelModalOpen: false}))}>{props.Text.cancelOnboarding.keepAdding}</button>
                </div>
            </ModalBody>
        </Modal>
    )


    return (
        <div>
            {renderModal()}
        </div>
    )
}

export default CancelOnboarding