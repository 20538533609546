import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import './Progressbar.css';

const Progressbar =(props)=>{
return(
    <div className="progressbar_accept">
        <span style={{width: props.value}}></span>
    </div>
) 
}
export default Progressbar;