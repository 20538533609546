import { UPDATEFLASHSTATE } from './flash.types';

const INITIAL_STATE = {
    "data_loaded": false,
    "industry_data_loaded": false,
    "industry_data_error": false,
    "industry_data": [],
    "loading": false,
    "onboarded": false,
    "keys": {},
    "complete_test": false,
    "complete_live": false,
    "flash_mode": "test",
    "logout": false,
    "publicError": false,
    "refresh_test_secret_key": false,
    "refresh_live_secret_key": false,
    "refresh_test_public_key": false,
    "refresh_live_public_key": false,
    "refresh_test_secret_key_message": null,
    "refresh_live_secret_key_message": null,
    "refresh_test_public_key_message": null,
    "refresh_live_public_key_message": null,
    "onboarding_error": false,
    "onboarding_error_message": null,
    "documentStatus": ""
};

const flash_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATEFLASHSTATE:
            return {
                ...action.payload.newState
            };

        default: return state;
    }

};

export default flash_reducer;