import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { auth } from './tools/Auth';
import {dependencies} from './../src/tools/Dependencies'
import './App.scss';
import Waiting from './views/Waiting/waiting';
import Popup from './views/Pages/PopUp/Popup';
import localText from './../src/staticText/main';
import bulkDisbursement from './views/Pages/bulk_disbursement/bulkDisbursement';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Onboarding from './views/Pages/Onboarding/Onboarding';
const loading = () => <div className="animated fadeIn pt-3 text-center full_loading"><Waiting height="400vh" /></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});
const DefaultLayoutPartnerNew = Loadable({
  loader: () => import('./containers/DefaultLayout/DefaultLayout_partner_new'),
  loading
});
const DefaultLayoutAccept = Loadable({
  loader: () => import('./containers/DefaultLayout/DefaultLayout_accept'),
  loading
});
const BankLayout = Loadable({
  loader: () => import('./containers/BankLayout'),
  loading
});

const TerminalsLayout = Loadable({
  loader: () => import('./containers/TerminalsLayout'),
  loading
});
// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/LoginAccept'),
  loading
});
const Jawaal_Login = Loadable({ loader: () => import('./views/Pages/Jawaaal_Login/Jawaal_login'), loading })
const Vodafone_Login = Loadable({ loader: () => import('./views/Pages/Vodafone_Pages/Login/Vodafone_Login'), loading })
const Register = Loadable({
  loader: () => import('./views/Pages/RegisterAccept'),
  loading
});
const RegisterPakistan = Loadable({
  loader: () => import('./views/Pages/RegisterAccept/RegisterPakistan'),
  loading
});
const UAERegister = Loadable({
  loader: () => import('./views/Pages/RegisterAccept/UAERegister'),
  loading
});
//RegisterSuccess
const RegisterSuccess = Loadable({
  loader: () => import('./views/Pages/RegisterAccept/SuccessRegister'),
  loading
});
const Signup_Jawaal = Loadable({ loader: () => import('./views/Pages/Signup_jawaal/Signup_jawaal'), loading })
const Vodafone_Signup = Loadable({ loader: () => import('./views/Pages/Vodafone_Pages/Signup/Vodafone_Signup'), loading })
//Vodafone RegisterSuccess
const Vodafone_RegisterSuccess = Loadable({
  loader: () => import('./views/Pages/Vodafone_Pages/Signup/SuccessRegister'),
  loading
});
const ForgetPassword = Loadable({
  loader: () => import('./views/Pages/ForgetPasswordAccept/ForgetPasswordAccept'),
  loading
});
const ChangePassword = Loadable({
  loader: () => import('./views/Pages/ChangePasswordAccept/ChangePasswordAccept'),
  loading
});
const Jawaal_forgetPass = Loadable({
  loader: () => import('./views/Pages/Jawaal_forgetPass/Jawaal_forgetPass'),
  loading
});
const Vodafone_forgetPass = Loadable({
  loader: () => import('./views/Pages/Vodafone_Pages/ForgetPass/Vodafone_forgetPass'),
  loading
});
const VodafoneChangePassword = Loadable({
  loader: () => import('./views/Pages/Vodafone_Pages/ChangePassword/VodafoneChangePassword'),
  loading
});
const Delivery = Loadable({
  loader: () => import('./views/Pages/Delivery/delivery'),
  loading
});
// Growth Engine
const GrowthReferring = Loadable({
  loader: () => import('./views/Pages/GrowthEngine/GrowthReferring'),
  loading
});
const GrowthSignup = Loadable({
  loader: () => import('./views/Pages/GrowthEngine/GrowthSignup'),
  loading
});
const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});
const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const getCurrentLang =() =>{
  if (dependencies.getFromLocalStorage("lang")) {
    return dependencies.getFromLocalStorage("lang")
  } else {
    return "en"
  }
}

const country = dependencies.getCountryFromDomain();
class App extends Component {
  renderLayoutAccept = () => {
    if(dependencies.getFromLocalStorage("acq_partner") == true){
      if(dependencies.getFromLocalStorage("is_redirect_to_new_portal") == true){
        return <DefaultLayoutPartnerNew location={window.location}/>
      }else{
        return <DefaultLayout />
      }
    }else{
      if (auth.getMerchantType() === "Merchant" || auth.getMerchantType() === "MerchantStaff") {
        if(dependencies.getFromLocalStorage("filledAnswer") == false && dependencies.getFromLocalStorage("filled_business_data") == false){
          return(
            <div className="popup-route">
              <Popup  Text={localText[getCurrentLang()].Popup} />
            </div>
          )
        }else {
          return <DefaultLayoutAccept location={window.location} />
        }
      } else if (auth.getMerchantType() === "Bank" || auth.getMerchantType() === "BankStaff") {
        return <BankLayout />
      } else if (auth.getMerchantType() === "Terminal") {
        return <TerminalsLayout />
      }
    }
  }

  renderCountryRegister = (props) => {
    switch(country) {
      case "PAK":
        return <RegisterPakistan match={props.match}/>
      case "ARE":
        return <UAERegister match={props.match}/>
      default:
        return <Register match={props.match}/>
    }
  }
  
  render() {
    console.log("version: 3.0.1");
    if (!dependencies.getFromLocalStorage("lang")) {
      dependencies.addToLocalStorage("lang", "en");
    }

    if (dependencies.getFromLocalStorage("lang") !== "en" && dependencies.getFromLocalStorage("lang") !== "ar") {
      dependencies.addToLocalStorage("lang", "en");
      window.location.reload();
    }
     const domain_name = window.location.hostname;
    const Home = () => {
      if (domain_name === 'checkout.jawwalpay.ps' || domain_name === "stg.jawwalpay.ps") {
        return (
          <BrowserRouter  basename="/portal2">
            <Switch>
              <Route exact path="/:lang?/login/" name="Login Page" component={!auth.isLoggedIn() ? Jawaal_Login : this.renderLayoutAccept} />
              <Route exact path="/:lang?/register/" name="Register Page" component={Signup_Jawaal} />
              <Route exact path="/:lang?/forgetpassword" name="Jawaal_forgetPass Page" component={Jawaal_forgetPass} />
              <Route path="/:lang?/" name="Home" component={auth.isLoggedIn() ? this.renderLayoutAccept : Jawaal_Login} />
            </Switch>
          </BrowserRouter>
        )
      } else if (domain_name === 'vodafonemerchants.paymobsolutions.com' || domain_name === "acceptstagingtestvodafone.paymobsolutions.com") {
        return (
          <BrowserRouter  basename="/portal2">
            <Switch>
              <Route exact path="/:lang?/login/" name="Login Page" component={!auth.isLoggedIn() ? Vodafone_Login : this.renderLayoutAccept} />
              <Route exact path="/:lang?/register/" name="Register Page" component={Vodafone_Signup} />
              <Route exact path="/:lang?/register/success" name="Vodafone Register Success Page" component={Vodafone_RegisterSuccess} />
              <Route exact path="/:lang?/forgetpassword/" name="Vodafone_forgetPass Page" component={Vodafone_forgetPass} />
              <Route exact path="/:lang?/changepassword/" name="Vodafone ChangePassword Page" component={VodafoneChangePassword} />
              <Route path="/:lang?/" name="Home" component={auth.isLoggedIn() ? this.renderLayoutAccept : Vodafone_Login} />
            </Switch>
          </BrowserRouter>
        )
      } else {
        return (
          <BrowserRouter basename="/portal2">
            <Switch>
              <Route exact path="/:lang?/login/" name="Login Page" component={!auth.isLoggedIn() ? Login : this.renderLayoutAccept} />
              <Route exact path="/:lang?/register/" name="Register Page" component={this.renderCountryRegister} />
              <Route exact path="/:lang?/register/success" name="Register Success Page" component={RegisterSuccess} />
              <Route exact path="/:lang?/forgetpassword/" name="ForgetPassword Page" component={ForgetPassword} />
              <Route exact path="/:lang?/changepassword/" name="ChangePassword Page" component={ChangePassword} />
              <Route exact path="/:lang?/growth/referring" name="Success Growth" component={GrowthReferring} />
              <Route exact path="/:lang?/growth/signup" name="Growth Engine" component={GrowthSignup} />
              <Route path="/:lang?/bulk_disbursement/" name="bulk disbursement" component={bulkDisbursement} />
              <Route path="/:lang?/" name="Home" component={auth.isLoggedIn() ? this.renderLayoutAccept : Login} />
            </Switch>
          </BrowserRouter>
        )
      }
    }
    return (
      <>
        <ToastContainer limit={1} />
        <BrowserRouter>
          <Switch>
            {Home()}
            <Route exact path="/delivery" name="Delivery Page" component={Delivery} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
