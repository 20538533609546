export const GET_NET_VOLUME = 'GET_NET_VOLUME';
export const GET_GROSS_VOLUME = 'GET_GROSS_VOLUME';
export const GET_POS_BRANCHES = 'GET_POS_BRANCHES';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_TOTAL_REFUNDS = 'GET_TOTAL_REFUNDS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const GET_TOTAL_CUSTOMERS = 'GET_TOTAL_CUSTOMERS';
export const GET_AVERAGE_PER_CUSTOMER = 'GET_AVERAGE_PER_CUSTOMER';
export const GET_TOTAL_TRANSFER = 'GET_TOTAL_TRANSFER';
export const SET_DATE = 'SET_DATE';
export const SET_COMPARISON_DATE = 'SET_COMPARISON_DATE';
export const SET_TYPE_COMPARISON = 'SET_TYPE_COMPARISON';
export const SET_PERIOD_SELECT = 'SET_PERIOD_SELECT';
export const SET_CHANNEL_TYPE = 'SET_CHANNEL_TYPE';
export const GET_TRANSACTIONS_PER_BANK_CARD = 'GET_TRANSACTIONS_PER_BANK_CARD';
export const GET_TRANSACTIONS_PER_DAY = 'GET_TRANSACTIONS_PER_DAY';
export const SET_CHARTS_LOADING = 'SET_CHARTS_LOADING';
