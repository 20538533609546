import { UPDATEMAINSTATE } from "./main.types";

const INITIAL_STATE = {
  subscribed: null,
  riskCenterSubscribed: null
};

const main_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATEMAINSTATE:
      return {
        ...action.payload.newState
      };

    default:
      return state;
  }
};

export default main_reducer;
