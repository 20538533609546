import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import * as amplitude from "@amplitude/analytics-browser";
import store from './redux/store';
import { dependencies } from './tools/Dependencies';

const MIN_ID_LENGTH = 1;

amplitude.init(dependencies.getAmplitudeApiKey(), null, {
  trackingOptions: {
    blacklist: ['[Amplitude] Page Viewed'],
  },
  minIdLength: MIN_ID_LENGTH
});
ReactDOM.render( <Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
