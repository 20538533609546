var cityStateDict = {
    "Sukh Chain Society": "Lahore",
    "PASCO Housing Society": "Lahore",
    "Fransisi Town": "Lahore",
    "Sarshar Town": "Lahore",
    "Allama Iqbal Town": "Lahore",
    "Samanabad": "Lahore",
    "Gulshan E Ravi": "Lahore",
    "Sanda Khurd": "Lahore",
    "Sanat Nagar": "Lahore",
    "Karim Park": "Lahore",
    "Shad Bagh": "Lahore",
    "Faruque Ganj": "Lahore",
    "Baghban Pura": "Lahore",
    "Shalimar Town": "Lahore",
    "Herbuns Pura": "Lahore",
    "Ram Garh": "Lahore",
    "Ittehad Colony": "Lahore",
    "Ali Park": "Lahore",
    "Officers Colony": "Lahore",
    "Burj Colony": "Lahore",
    "Suparco Colony": "Lahore",
    "PCSIR Housing Scheme": "Lahore",
    "Punjab University": "Lahore",
    "Punjab University Hostel": "Lahore",
    "Shadman Colony": "Lahore",
    "Johar Town": "Lahore",
    "Airline Society": "Lahore",
    "Faisal Town": "Lahore",
    "Wafaqi Colony": "Lahore",
    "Bostan Colony": "Lahore",
    "Hamdard Chowk": "Lahore",
    "Bagrian": "Lahore",
    "Lake City": "Lahore",
    "Shabir Town": "Lahore",
    "Opf Housing Scheme": "Lahore",
    "NFC Town": "Lahore",
    "Nespak Society": "Lahore",
    "Aitchison Society": "Lahore",
    "Railway Housing Society": "Lahore",
    "Gawal Mandi": "Lahore",
    "Qila Gujarsing": "Lahore",
    "Mazang": "Lahore",
    "Gor": "Lahore",
    "Gulberg 3": "Lahore",
    "Gulbahar Colony": "Lahore",
    "Ghazi Road": "Lahore",
    "Nishtar Colony": "Lahore",
    "Bedian Road": "Lahore",
    "Cavalry Ground": "Lahore",
    "Punjab Society Near Dha": "Lahore",
    "DHA 1": "Lahore",
    "DHA 4": "Lahore",
    "DHA 7": "Lahore",
    "Chachowali": "Lahore",
    "Cantt": "Lahore",
    "Askrai Villa 3": "Lahore",
    "Askrai Villa 6": "Lahore",
    "Askrai Villa 9": "Lahore",
    "Army Housing Society": "Lahore",
    "Barki Road": "Lahore",
    "Rang Mehal": "Lahore",
    "Gor I": "Lahore",
    "Gor IV": "Lahore",
    "Aurangzeb Block": "Lahore",
    "Tipu Block": "Lahore",
    "Usman Block": "Lahore",
    "Abu Bakar Block": "Lahore",
    "Gulberg 5": "Lahore",
    "CMA Colony": "Lahore",
    "Main Mir Colony": "Lahore",
    "Quaid e Azam Industrial Estate": "Lahore",
    "Salamatpura": "Lahore",
    "Taj Para": "Lahore",
    "Shaikh Zayed Hospital": "Lahore",
    "UCP": "Lahore",
    "Canal Garden": "Lahore",
    "Izmir Town": "Lahore",
    "Canal Park Society": "Lahore",
    "M M Alam Road": "Lahore",
    "Badar Block": "Lahore",
    "Education Town": "Lahore",
    "Jehanzeb Block": "Lahore",
    "Khyber Block": "Lahore",
    "Mehran Block": "Lahore",
    "Neelam Block": "Lahore",
    "Ravi Block": "Lahore",
    "Sutlej Block": "Lahore",
    "Zeenat Block": "Lahore",
    "Fort Villas": "Lahore",
    "Infantry Road": "Lahore",
    "Tufail Road": "Lahore",
    "Munir Road": "Lahore",
    "Zaman Colony": "Lahore",
    "Valencia Town Block A1": "Lahore",
    "Valencia Town Block C": "Lahore",
    "Valencia Town Block H": "Lahore",
    "Bahria Town": "Lahore",
    "Eden Canal Villas": "Lahore",
    "Doctors Society": "Lahore",
    "Awan Town": "Lahore",
    "Saidpur": "Lahore",
    "Ichhra": "Lahore",
    "Riwaz Garden": "Lahore",
    "Sanda Kalan": "Lahore",
    "Bilal Gang": "Lahore",
    "Ravi Road": "Lahore",
    "Usman Ganj": "Lahore",
    "Badami Bagh": "Lahore",
    "Star Town": "Lahore",
    "Khudad Town": "Lahore",
    "Shalimar Link Road": "Lahore",
    "Mughal Pura": "Lahore",
    "Samia Town": "Lahore",
    "Guldasit Colony": "Lahore",
    "Mustafa Bad": "Lahore",
    "Canal Road": "Lahore",
    "Engineer Cooperative Society": "Lahore",
    "PCSIR Housing Society": "Lahore",
    "Jinnah Hospital": "Lahore",
    "New Garden Town": "Lahore",
    "Fc College": "Lahore",
    "PIA Society": "Lahore",
    "Shama Colony": "Lahore",
    "Model Town": "Lahore",
    "Township": "Lahore",
    "Baba Farid Colony": "Lahore",
    "Green Town": "Lahore",
    "Punjab Govt Employs Coparative Society": "Lahore",
    "Lalzar Colony": "Lahore",
    "Main Khayaban Road": "Lahore",
    "Nasheman Iqbal Housing Scheme": "Lahore",
    "Wapda Town": "Lahore",
    "Anar Kali": "Lahore",
    "Davis Road": "Lahore",
    "Dharampura": "Lahore",
    "Lakshmi Chowk": "Lahore",
    "Wapda House": "Lahore",
    "Ganga Ram Hospital": "Lahore",
    "Gulberg 1": "Lahore",
    "Firozpur Road": "Lahore",
    "Madina Colony": "Lahore",
    "Chungi Amer Sadhu": "Lahore",
    "Youhna Bad": "Lahore",
    "Alfalah Town": "Lahore",
    "Super Town": "Lahore",
    "Sui Gas Society": "Lahore",
    "DHA 2": "Lahore",
    "DHA 5": "Lahore",
    "DHA 8": "Lahore",
    "Gohawa": "Lahore",
    "Askrai Villa 1": "Lahore",
    "Askrai Villa 4": "Lahore",
    "Askrai Villa 7": "Lahore",
    "Askrai Villa 10": "Lahore",
    "Eden Avenue": "Lahore",
    "Paragon City": "Lahore",
    "Architects Engineers Society": "Lahore",
    "Gor II": "Lahore",
    "Gor V": "Lahore",
    "Aibak Block": "Lahore",
    "Shersha Block": "Lahore",
    "Ali Block": "Lahore",
    "Ahmed Block": "Lahore",
    "Gulberg 4": "Lahore",
    "Saint John Park": "Lahore",
    "Upper Mall Scheme": "Lahore",
    "Kainchi": "Lahore",
    "Sarwar Road": "Lahore",
    "Moon Market": "Lahore",
    "Gulshan Block": "Lahore",
    "Muhafiz Town": "Lahore",
    "Tricon Valley": "Lahore",
    "Iqbal Avenue": "Lahore",
    "Main Gulberg Boulevard": "Lahore",
    "Asif Block": "Lahore",
    "Chenab Block": "Lahore",
    "Huma Block": "Lahore",
    "Kamran Block": "Lahore",
    "Mamdoot Block": "Lahore",
    "Mustafa Town": "Lahore",
    "Nishtar Block": "Lahore",
    "Rachna Block": "Lahore",
    "Sikandar Block": "Lahore",
    "Makkah Colony": "Lahore",
    "Bridge Colony": "Lahore",
    "Khursheed Alam Road": "Lahore",
    "Lawrence Road": "Lahore",
    "R A Bazaar": "Lahore",
    "LDA Colony": "Lahore",
    "Valencia Town Block A2": "Lahore",
    "Valencia Town Block F": "Lahore",
    "Valencia Town Block H1": "Lahore",
    "Valencia Town Block L": "Lahore",
    "EME DHA Society": "Lahore",
    "Eden Homes": "Lahore",
    "Thokar Niaz Baig": "Lahore",
    "Canal Breeze": "Lahore",
    "Sabzazar": "Lahore",
    "Wahdat Colony": "Lahore",
    "Band Road": "Lahore",
    "Islam Pura": "Lahore",
    "Amin Park": "Lahore",
    "Data Nagar": "Lahore",
    "Misri Shah": "Lahore",
    "Darbar Gurreh Shah": "Lahore",
    "Wapda Colony": "Lahore",
    "Mahmood Booty Ring Road": "Lahore",
    "Ghousia Colony": "Lahore",
    "Nabi Pura": "Lahore",
    "Muslima Bad": "Lahore",
    "New PAF Colony": "Lahore",
    "Fortress Stadium": "Lahore",
    "Canal View Colony": "Lahore",
    "Tech Society": "Lahore",
    "Alpha Co Operative": "Lahore",
    "Allama Iqbal Medical College": "Lahore",
    "Muslim Town": "Lahore",
    "Shah Jamal": "Lahore",
    "Revenue Society": "Lahore",
    "Peco Road": "Lahore",
    "New Mustafa Colony": "Lahore",
    "Kot Lakhpat": "Lahore",
    "Race Course Town": "Lahore",
    "PCSIR Society": "Lahore",
    "Raiwind Road": "Lahore",
    "West Wood Colony": "Lahore",
    "Opf Colony": "Lahore",
    "Tariq Garden": "Lahore",
    "Khyaban E Amin": "Lahore",
    "Mall Road": "Lahore",
    "Garhi Shahu": "Lahore",
    "Mayo Hospital": "Lahore",
    "Punjab University Old Campus": "Lahore",
    "Jail Road": "Lahore",
    "Race Course Park Surrounding Area": "Lahore",
    "Gulberg 2": "Lahore",
    "Askari Colony": "Lahore",
    "Walton Road": "Lahore",
    "Awan Market": "Lahore",
    "Abdalian Housing Society": "Lahore",
    "Chararrd Village": "Lahore",
    "Rehman Garden": "Lahore",
    "State Life Society": "Lahore",
    "DHA 3": "Lahore",
    "DHA 6": "Lahore",
    "Lidhar": "Lahore",
    "Airport Road": "Lahore",
    "Askrai Villa 2": "Lahore",
    "Askrai Villa 5": "Lahore",
    "Askrai Villa 8": "Lahore",
    "Askrai Villa": "Lahore",
    "Eden View": "Lahore",
    "LUMS": "Lahore",
    "Civic Center": "Lahore",
    "Gor III": "Lahore",
    "Tariq Block": "Lahore",
    "Babar Block": "Lahore",
    "Atta Turk Block": "Lahore",
    "Garden Block": "Lahore",
    "Iqbal Ave Housing Society": "Lahore",
    "Ferozpur Road": "Lahore",
    "Saddar": "Lahore",
    "Temple Road": "Lahore",
    "K B Society": "Lahore",
    "Taj Bagh": "Lahore",
    "Nizam Block": "Lahore",
    "Shaukat Khanum Hospital": "Lahore",
    "LDA Avenue": "Lahore",
    "Jubli Town": "Lahore",
    "Canal Bank": "Lahore",
    "Falcon Complex": "Lahore",
    "Multan Road": "Lahore",
    "College Block": "Lahore",
    "Hunza Block": "Lahore",
    "Karim Block": "Lahore",
    "Maraghzar Colony": "Lahore",
    "Nargis Block": "Lahore",
    "Pak Block": "Lahore",
    "Raza Block": "Lahore",
    "Umer Block": "Lahore",
    "Khuda Buksh Colony": "Lahore",
    "Lahore Gym Khana": "Lahore",
    "Nisar Colony": "Lahore",
    "Nasheman Colony": "Lahore",
    "Shahtaj Colony": "Lahore",
    "University of Lahore": "Lahore",
    "Valencia Town Block A3": "Lahore",
    "Valencia Town Block G": "Lahore",
    "Valencia Town Block J": "Lahore",
    "Valencia Town Block P1": "Lahore",
    "Faisal Mosque (E-8)": "Islamabad",
    "Daman e Koh (E-7)": "Islamabad",
    "Mausoleum of Pir Mehar Ali Shah (E-11)": "Islamabad",
    "The Centaurus Mall (F-8)": "Islamabad",
    "Fatima Jinnah Park (F-9)": "Islamabad",
    "Marriot Hotel (F-5)": "Islamabad",
    "Jinnah Convention Center (G-5)": "Islamabad",
    "Serena Hotel (G-5)": "Islamabad",
    "Supreme Court of Pakistan (G-5)": "Islamabad",
    "Red Mosque (G-6)": "Islamabad",
    "Pakistan Institute of Medical Sciences (PIMS – G8)": "Islamabad",
    "Karachi Company Shopping Market (G-9)": "Islamabad",
    "International Islamic University Islamabad (IIUI – H10)": "Islamabad",
    "National University of Modern Languages (NUML – H9)": "Islamabad",
    "Allama Iqbal Open University (AIOU – H8)": "Islamabad",
    "Liaquatabad Town": "Karachi",
    "North Nazimabad Town": "Karachi",
    "Gulberg Town": "Karachi",
    "New Karachi Town": "Karachi",
    "Gulshan-e-Iqbal Town": "Karachi",
    "Jamshed Town": "Karachi",
    "Saddar Town": "Karachi",
    "Lyari Town": "Karachi",
    "Orangi Town": "Karachi",
    "Kemari Town": "Karachi",
    "SITE Town": "Karachi",
    "Baldia Town": "Karachi",
    "Korangi Town": "Karachi",
    "Shah Faisal Town": "Karachi",
    "Landhi Town": "Karachi",
    "Malir Town": "Karachi",
    "Gadap Town": "Karachi",
    "Bin Qasim Town": "Karachi",
    "Gulshan-e-Ghazi": "Karachi",
    "Ittehad Town": "Karachi",
    "Islam Nagar": "Karachi",
    "Nai Abadi": "Karachi",
    "Saeedabad": "Karachi",
    "Muslim Mujahid Colony": "Karachi",
    "Muhajir Camp": "Karachi",
    "Rasheedabad": "Karachi",
    "Ibrahim Hyderi": "Karachi",
    "Rehri": "Karachi",
    "Cattle Colony": "Karachi",
    "Qaidabad": "Karachi",
    "Landhi Colony": "Karachi",
    "Gulshan-e-Hadeed": "Karachi",
    "Gaghar": "Karachi",
    "Murad Memon Goth": "Karachi",
    "Darsano Chana": "Karachi",
    "Gadap": "Karachi",
    "Gujro": "Karachi",
    "Songal": "Karachi",
    "Maymarabad": "Karachi",
    "Yousuf Goth": "Karachi",
    "Manghopir": "Karachi",
    "Ghaniyabad": "Karachi",
    "DEH MALH": "Karachi",
    "Azizabad": "Karachi",
    "Karimabad": "Karachi",
    "Aisha Manzil": "Karachi",
    "Ancholi": "Karachi",
    "Naseerabad": "Karachi",
    "Yaseenabad": "Karachi",
    "Water Pump": "Karachi",
    "Delhi Mercantile Society": "Karachi",
    "Civic Centre": "Karachi",
    "Pir Ilahi Buksh Colony": "Karachi",
    "Essa Nagri": "Karachi",
    "Gulshan-e-Iqbal": "Karachi",
    "Gillani Railway Station": "Karachi",
    "Dalmia": "Karachi",
    "Jamali Colony": "Karachi",
    "Gulshan-e-Iqbal II": "Karachi",
    "Pehlwan Goth": "Karachi",
    "Metroville Colony": "Karachi",
    "Gulzar-e-Hijri": "Karachi",
    "Safooran Goth": "Karachi",
    "Faisal Cantt.": "Karachi",
    "New Dhoraji": "Karachi",
    "New Mirpur":"Azad Kashmir",
    "Muzaffarabad":"Azad Kashmir",
    "Kotli":"Azad Kashmir",
    "Quetta":"Balochistan",
    "Turbat":"Balochistan",
    "Khuzdar":"Balochistan",
    "Chaman":"Balochistan",
    "Zhob":"Balochistan",
    "Gwadar":"Balochistan",
    "Kalat":"Balochistan",
    "Dainyor":"Gilgit-Baltistan",
    "Gilgit":"Gilgit-Baltistan",
    "Islamabad":"Islamabad",
    "Peshawar":"KPK",
    "Saidu Sharif":"KPK",
    "Mardan":"KPK",
    "Kohat":"KPK",
    "Abbottabad":"KPK",
    "Nowshera":"KPK",
    "Swabi":"KPK",
    "Dera Ismail Khan":"KPK",
    "Charsadda":"KPK",
    "Mansehra":"KPK",
    "Bannu":"KPK",
    "Timargara":"KPK",
    "Parachinar":"KPK",
    "Tank":"KPK",
    "Hangu":"KPK",
    "Risalpur Cantonment":"KPK",
    "Karak":"KPK",
    "Chitral":"KPK",
    "Kulachi":"KPK",
    "Lahore":"Punjab",
    "Faisalabad":"Punjab",
    "Rawalpindi":"Punjab",
    "Gujranwala":"Punjab",
    "Multan":"Punjab",
    "Bahawalpur":"Punjab",
    "Sargodha":"Punjab",
    "Sialkot City":"Punjab",
    "Chiniot":"Punjab",
    "Shekhupura":"Punjab",
    "Jhang City":"Punjab",
    "Dera Ghazi Khan":"Punjab",
    "Gujrat":"Punjab",
    "Rahimyar Khan":"Punjab",
    "Kasur":"Punjab",
    "Sahiwal":"Punjab",
    "Okara":"Punjab",
    "Mandi Burewala":"Punjab",
    "Saddiqabad":"Punjab",
    "Muridke":"Punjab",
    "Muzaffargarh":"Punjab",
    "Khanpur":"Punjab",
    "Gojra":"Punjab",
    "Mandi Bahauddin":"Punjab",
    "Bahawalnagar":"Punjab",
    "Pakpattan":"Punjab",
    "Zafarwal":"Punjab",
    "Jaranwala":"Punjab",
    "Ahmadpur East":"Punjab",
    "Vihari":"Punjab",
    "Kamalia":"Punjab",
    "Kot Addu":"Punjab",
    "Khushab":"Punjab",
    "Chishtian":"Punjab",
    "Hasilpur":"Punjab",
    "Attock Khurd":"Punjab",
    "Arifwala":"Punjab",
    "Mianwali":"Punjab",
    "Jalalpur Jattan":"Punjab",
    "Bhakkar":"Punjab",
    "Dipalpur":"Punjab",
    "Kharian":"Punjab",
    "Mian Channun":"Punjab",
    "Bhalwal":"Punjab",
    "Pattoki":"Punjab",
    "Harunabad":"Punjab",
    "Kahror Pakka":"Punjab",
    "Toba Tek Singh":"Punjab",
    "Samundri":"Punjab",
    "Shakargarh":"Punjab",
    "Sambrial":"Punjab",
    "Shujaabad":"Punjab",
    "Hujra Shah Muqim":"Punjab",
    "Kabirwala":"Punjab",
    "Lala Musa":"Punjab",
    "Chunian":"Punjab",
    "Nankana Sahib":"Punjab",
    "Pasrur":"Punjab",
    "Chenab Nagar":"Punjab",
    "Abdul Hakim":"Punjab",
    "Hassan Abdal":"Punjab",
    "Kundian":"Punjab",
    "Murree":"Punjab",
    "Gilwala":"Punjab",
    "Karachi":"Sindh",
    "Hyderabad City":"Sindh",
    "Sukkur":"Sindh",
    "Larkana":"Sindh",
    "Nawabshah":"Sindh",
    "Mirpur Khas":"Sindh",
    "Jacobabad":"Sindh",
    "Dadu":"Sindh",
    "Khairpur Mir’s":"Sindh",
    "Tando Allahyar":"Sindh",
    "Kandhkot":"Sindh",
    "Kambar":"Sindh",
    "Jamshoro":"Sindh",
    "Umarkot":"Sindh",
    "Mithi":"Sindh",
    "Mian Sahib":"Sindh"
}
export default cityStateDict;