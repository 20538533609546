import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import option1 from '../../../../assets/Icons/option1.png';
import workerImg from '../../../../assets/Icons/worker.png';
import workerColoring from '../../../../assets/Icons/workerColor.png';
import option1Coloring from '../../../../assets/Icons/option1Color.png';
import './Steps.scss';

const StepOne =(props)=>(
  <div>
 <FormGroup tag="fieldset" className="stepFieldset">
<legend>{props.Text.question1}</legend>
 <FormGroup check={props.ChangeHandler} className="stepOne-container">
   
    <Label check>
   <div className="icon-container">
     <Input type="radio" name="radio1" value="1"  checked={props.business === '1'} onChange={props.individualBusinessChange}  required/>
     <img src={props.business === '1'? workerColoring:option1}  alt="option1" className="popupIcon"/>
     </div>
     <div className={`${props.business === '1'? 'activation':'options-content'}`}>
     <h5>{props.Text.quOption1}</h5>
     <span>
     {props.Text.hint1}
   </span>
   </div>
   </Label>
 </FormGroup>
 <FormGroup check={props.ChangeHandler} className="stepOne-container">
   <Label check>
     <div className="icon-container">
     <Input type="radio" name="radio2" value ="0" checked={props.business === '0'} onChange={props.individualBusinessChange}  required/>
     <img src={props.business === '0' ? option1Coloring :workerImg}  alt="workerImg" className="popupIcon"/>
     </div>
     <div className={`${props.business === '0'? 'activation':'options-content'}`}>
     <h5>{props.Text.quOption2}</h5>
            {props.business === '0' && <p className="smallHint">{props.Text.smallHint}</p>}
     <span>
   {props.Text.hint2}
   </span>
   </div>
   </Label>
 </FormGroup>
      <div className="stepone-hint">{props.Text.hint1}</div> 
 </FormGroup>
 <div className="ErrorMessage"> {props.invalidMesage&&(!props.business) && props.stepperMessage}</div>
 </div>
) 
export default StepOne;