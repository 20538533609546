import React from 'react';

const SucessContainer =(props)=>{
 return(
<div className="sucess-container">
<i className="fa fa-check-circle" aria-hidden="true" />
<div className="sucess-content">
    <h1> {props.Text.sucessContentHeader}</h1>
<p>{props.Text.sucessContent}</p>
</div>
</div>
 )
}
export default SucessContainer;