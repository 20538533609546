import React from 'react';
// import Loader from './../../assets/img/loader.svg';
import { StageSpinner } from "react-spinners-kit";
import './waiting.css';

const waiting = (props) => {
    
    const domain_name = window.location.hostname;
    let color = "#2A3F54";
    if (domain_name === 'checkout.jawwalpay.ps' || domain_name === 'stg.jawwalpay.ps'){
        color = "#69CAC7";
    }
    return (
        <div className="waitingComponent" style={{height: props.height}}>
            <StageSpinner size={90} color={color} />
        </div>        
    );
}

export default waiting;