import {httpClient} from './HttpClient';
import cookie from 'react-cookies';
import { dependencies } from './Dependencies';
import { toast } from 'react-toastify';
import textFile from './text.json';

const text = textFile[dependencies.getFromLocalStorage("lang")];

let cookies = cookie.loadAll();
class Auth {
    
logout( logoutMsg = text.expiredSessionMessage ) {
    toast.warn(logoutMsg, {
        theme: "colored",
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 4000,
    });
    toast.clearWaitingQueue();
    setTimeout(() => { 
        let config = {
            headers: {
                //"Cache-Control": "no-cache",
                "Content-Type": "application/json",
                "Accept":"application/json",
                "Authorization": "Bearer " + this.getMerchantToken(),
            }
        }
        httpClient.get(
            '/api/auth/logout',
            config,
            (resp) => {
                this.finalLogout();
            },
            (error) => {
                this.finalLogout();
            }
        )
    }, 3000);
}
hidden_logout() {
    let config = {
        headers: {
            //"Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": "Bearer " + this.getMerchantToken(),
        }
    }
    httpClient.get(
        '/api/auth/logout',
        config,
        (resp) => {
            this.finalLogout();
        },
        (error) => {
            this.finalLogout();
        }
    )
}
finalLogout(){
    let not_remove_arr = ['lang']
    this.deleteAllCookies(not_remove_arr);
    let currentLang = dependencies.getFromLocalStorage("lang");
    const isXpence = dependencies.getFromLocalStorage("xpence");
    localStorage.clear();
    dependencies.addToLocalStorage("lang", currentLang);
    dependencies.addToLocalStorage("xpence", isXpence);
    window.location.reload();
}
deleteAllCookies = (not_remove_arr) => {
    Object.keys(cookies).map((key) => {
        if(!not_remove_arr.includes(key)){
            cookie.remove(key, { path: '/' })
            cookie.remove(key, { path: '/portal2/en' })
            cookie.remove(key, { path: '/portal2/ar' })
        }
    })
}
isLoggedIn() {
    return !!dependencies.getFromLocalStorage("token") && !!dependencies.getFromLocalStorage("id") && !!dependencies.getFromLocalStorage("type") && dependencies.getFromLocalStorage("permNames");
}

getMerchantName(){
    if (dependencies.getFromLocalStorage("name")) {
        return dependencies.getFromLocalStorage("name");
    }
    return null;
}
getMerchantLogo(){
    if (dependencies.getFromLocalStorage("logo_url")) {
        return dependencies.getFromLocalStorage("logo_url");
    }
    return null;
}
getMerchantId(){
    if (dependencies.getFromLocalStorage("id")) {
        return dependencies.getFromLocalStorage("id");
    }
    return null;
}
getMerchantType(){
    if (dependencies.getFromLocalStorage("type")) {
        return dependencies.getFromLocalStorage("type");
    }
    return null;
}
getMerchantToken() {
    if (dependencies.getFromLocalStorage("token")) {
        return dependencies.getFromLocalStorage("token");
    }
    return null;
}
getMerchantPermNames(){
    if (dependencies.getFromLocalStorage("permNames")) {
        return dependencies.getFromLocalStorage("permNames");
    }
    return null;
}
}

export let auth = new Auth();
