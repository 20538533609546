var dubaiStateDict = {
 "Dubai": "Dubai",
 "Ud Al Bayda’": "Dubai",
 "Qaraytaysah": "Dubai",
 "Margham": "Dubai",
 "Jabal Qimah": "Dubai",
 "Hunaywah": "Dubai",
 "Al Hayl": "Dubai",
 "‘Urqub Juwayza": "Dubai",
 "Ferij al Muhadham": "Dubai",
 "Al Faq": "Dubai",
 "Hatta": "Dubai",
 "Al Lusayli ": "Dubai",
 "Warisan": "Dubai",
 "Sharjah": " Sharjah",
 "Hasat al Bidiyah": " Sharjah",
 "Al Mudaifi": " Sharjah",
 "Lulayyah": " Sharjah",
 "Al Jazirah": " Sharjah",
 "Wadi Shis": " Sharjah",
 "Khor Fakkan": " Sharjah",
 "Ash Shu'ayb": " Sharjah",
 "Hayawa": " Sharjah",
 "Rufaysah": " Sharjah",
 "Rafa‘": " Sharjah",
 "Shis": " Sharjah",
 "Nahwa": " Sharjah",
 "Al Hamriya": " Sharjah",
 "Al Dhaid": " Sharjah",
 "Al Awdah": " Sharjah",
 "Qidfa‘": " Sharjah",
 "‘Uqair": " Sharjah",
 "Ghayl": " Sharjah",
 "Kalba": " Sharjah",
 "Mirbah": " Sharjah",
 "Harrah": " Sharjah",
 "Hayat": " Sharjah",
 "Girath": " Sharjah",
 "Nuhayy": " Sharjah",
 "Abu Dhabi": "Abu Dhabi",
 "Al Ayn": "Abu Dhabi",
 "‘Ajman": "‘Ajman",
 "Ras Al-Khaimah": "Ras Al-Khaimah",
 "Fujairah": "Fujairah",
 "Umm Al Quwain": "Umm Al Quwain",
 "Madinat Zayed ": "Abu Dhabi",
 "Shariyah": "‘Ajman",
 "Masfout": "‘Ajman",
 "Sufayri": "‘Ajman",
 "Zubara": "Fujairah",
 "Al Bithnah": "Fujairah",
 "Mu‘tariḑah": "Fujairah",
 "Al Theeb": "Fujairah",
 "Wabayn": "Fujairah",
 "Al Qurayyah": "Fujairah",
 "Al Manama": "Fujairah",
 "‘Abadilah": "Fujairah",
 "Al Halah": "Fujairah",
 "Al Ghurfah": "Fujairah",
 "Al Hail": "Fujairah",
 "Al Fuqait": "Fujairah",
 "Jazirat Badiyah": "Fujairah",
 "Mukhtaraqah": "Fujairah",
 "Yalah": "Fujairah",
 "Tayybah": "Fujairah",
 "Harat Zutut":"Fujairah",
 "Al Uyaynah": "Fujairah",
 "Roul Dadna": "Fujairah",
 "Al Righailat": "Fujairah",
 "Tawian": "Fujairah",
 "Shawiyah": "Fujairah",
 "Dibba": "Fujairah",
 "Sinnah": "Fujairah",
 "Riyamah": "Fujairah",
 "Dub": "Fujairah",
 "Sram": "Fujairah",
 "‘Asimah": "Ras Al-Khaimah",
 "Ahfara": "Ras Al-Khaimah",
 "Wahlah": "Ras Al-Khaimah",
 "Thouban": "Fujairah",
 "Ihfarah": "Fujairah",
 "Al Gissemari": "Fujairah",
 "Al Khlaibiyah": "Fujairah",
 "Zanhah": "Fujairah",
 "Skamkam": "Fujairah",
 "Dahir": "Fujairah",
 "Jareef": "Fujairah",
 "al Kubus": "Fujairah",
 "‘Ashashah": "Fujairah",
 "Wadi Siji": "Ras Al-Khaimah",
 "‘Al Aqah": "Fujairah",
 "Ras Dibba": "Fujairah",
 "Dab‘Ah": "Ras Al-Khaimah",
 "Murbad": "Fujairah",
 "Safad": "Fujairah",
 "Tariqat Ja`d": "Fujairah",
 "Mawrid": "Ras Al-Khaimah",
 "Tarif Kalba": "Fujairah"
}

export default dubaiStateDict;