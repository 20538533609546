import React from 'react';
import logo from '../../assets/img/brand/logo.svg';
import './SignHeader.scss';

const SignHeader = () => {
    return (
        <a className="bg-img logo-style logo_link">
            <img src={ logo} alt="accept" />
        </a>   
    );
}

export default SignHeader;