import React from 'react';
import {FormGroup, Label, Input } from 'reactstrap';
import VForm from 'react-validation/build/form';
import  retail from '../../../../assets/Icons/stepOption1.png';
import standalone from '../../../../assets/Icons/stepOption2.png';
import integration from '../../../../assets/Icons/socialmedia.png';
import retailColor from '../../../../assets/Icons/stepOption1Color.png';
import standaloneColor from '../../../../assets/Icons/stepOption2Color.png';
import socialmediaColor from '../../../../assets/Icons/socialmediaColor.png';
import './Steps.scss';



const StepTwo = (props) =>{
 return(
  <div>
   <VForm onSubmit={props.HandleStepSubmit}>
  <FormGroup tag="fieldset">
  <legend className="steptwo-legand">{props.Text.question2}</legend>
  <div className="stepone-hint">
           {/* <span className="hint-step2"> */}
           {props.Text.selectOptions}
           {/* </div></span>
  } */}
    </div>
  <FormGroup className="steptwo-form">
  <Label check>
  <div className="icon-container">
  <Input
    name="standalone"
    type="checkbox"
    checked={props.retail}
    onChange={props.retailHandleChange}  />
    <img src={props.retail? retailColor :retail} alt="integration"  className="popupIcon"/>
    </div>
    <div className={`${props.retail? 'activation':'options-content'}`}>
   <h5> {props.Text.qaOption1}</h5>
    </div>
</Label>
  </FormGroup>
  <FormGroup className="steptwo-form">
  <Label check>
  <div className="icon-container">
  <Input
    name="social_media"
    type="checkbox"
    checked={props.standalone }
    onChange={props.standaloneChangeHandle}  />
    <img src={props.standalone?standaloneColor:standalone} alt="standalone" className="popupIcon"/>
    </div>
    <div className={`${props.standalone? 'activation':'options-content'}`}>
    <h5>{props.Text.qaOption2}</h5>
    </div>
</Label>
  </FormGroup>
  <FormGroup className="steptwo-form">
  <Label check>
  <div className="icon-container">
  <Input
    name="Retail"
    type="checkbox"
    checked={props.integration}
    onChange={props.SocialHandleChange}  />
    <img src={props.integration?socialmediaColor:integration} alt="retail" className="popupIcon"/>
    </div>
    <div className={`${props.integration? 'activation':'options-content'}`}>
   <h5>{props.Text.qaOption3}</h5>
   </div>
</Label>
  </FormGroup>
  </FormGroup>
  </VForm>
  </div>
 ) 
 }
export default StepTwo;