import { dependencies } from '../tools/Dependencies';
class AppConfig {

    getServerUrl() {
        // STOP EDITING THIS FILE..
        if(window.location.hostname == 'localhost'){
            //return "https://portal.weaccept.co";
            // return "https://accept-alpha.paymob.com";
            return "https://international-staging.paymob.com";
        }else{
            if(!window.location.port || window.location.port == ""){
                return "https://" + window.location.hostname;
            }else{
                return "https://" + window.location.hostname + ":" + window.location.port;
            }
        }
    }
    getGrowthServerUrl() {
        // STOP EDITING THIS FILE..
        if(window.location.hostname == 'localhost' || window.location.hostname == 'acceptstagingtest.paymobsolutions.com'){
            return "https://growthenginestaging.paymobsolutions.com";
        }else{
            return "https://growthengine.paymobsolutions.com";
        }
    }
    getOnboardingServerUrl() {
      const domain = window.location.hostname;
    
      const getConfigForDomain = (domain) => {
        const config = {
          "international-staging.paymob.com": {
            ARE: "https://merchantappuae.paymobsolutions.com",
            PAK: "https://merchantapppakistan.paymobsolutions.com",
            SAU: "https://merchantappstaging.paymobsolutions.com",
            OMN: "https://merchantappstaging.paymobsolutions.com",
            default: "https://merchantappstaging.paymobsolutions.com"
          },
          "uae.paymob.com": { default: "https://uae-merchant-backend.paymob.com" },
          "pakistan.paymob.com": { default: "https://merchantapppakistan.paymob.com" },
          "ksa.paymob.com": { default: "https://merchantappprod.paymobsolutions.com" },
          "ksa2.paymob.com": { default: "https://merchantappprod.paymobsolutions.com" },
          "oman.paymob.com": { default: "https://merchantappprod.paymobsolutions.com" },
          default: { default: "https://merchantappstaging.paymobsolutions.com" } // localhost
        };
    
        return config[domain] || config.default;
      };
    
      const countryCode = dependencies.getFromLocalStorage("country");
      const config = getConfigForDomain(domain);
    
      return (countryCode && config[countryCode]) || config.default;
    }
    getRiskCenterServerUrl() {
        if (window.location.hostname === "localhost") {
            return "https://international-staging.paymob.com/api/ecommerce/risk-center-proxy/send";
        } else {
            return `https://${window.location.hostname}/api/ecommerce/risk-center-proxy/send`;
        }
    }
}

export let appConfig = new AppConfig();
